import React, { useState, useEffect, useRef } from "react";
import ClientVideoContext from "./ClientVideoContext";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import axios from 'axios';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {browserName} from 'react-device-detect';

const video_bandwidth=global.videoBandwidth;
let clientreferenceno="123456789";
let global_otherUser='';
const SERVER_URL=global.CONFIG_SERVER_URL;
const WEBSITE_URL=global.WEBSITE_URL;
const CLIENT_VIDEO_RESOLUTION_WIDTH=global.CLIENT_VIDEO_RESOLUTION_WIDTH;
const CLIENT_VIDEO_RESOLUTION_HEIGHT=global.CLIENT_VIDEO_RESOLUTION_HEIGHT;
const CLIENT_VIDEO_FRAMERATE=global.CLIENT_VIDEO_FRAMERATE;

export const socket=io(SERVER_URL,{query:{"type":"client","referenceno":clientreferenceno}});
const ClientVideoState = ({ children}) => {

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [chat, setChat] = useState([]);
  const [name, setName] = useState("Yuvaraj");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [userName, setUserName] = useState("");
  const [otherUser, setOtherUser] = useState("");
  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState(true);
  const [msgRcv, setMsgRcv] = useState("");
  const [clientName, setClientName] = useState("");
  const [client_referenceno,setclient_referenceno] = useState("");
  const [hostreferenceno,sethostreferenceno]=useState("");
  const [curentcallerType, setcurentcallerType] = useState("");
  const [clientcallerType,setclientcallerType]= useState(false);
  const [clientorgid,setclientorgid]= useState("");
  const [initcall, setinitcall] = useState(false);
  const [streamflag, setstreamflag] = useState(false);
  const [endcallMessage,setendcallMessage] = useState(false);
  
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const maxframerate = global.maxframerate;

  useEffect(() => {

      setcurentcallerType("client");
      setclientcallerType(true);
  
      navigator.mediaDevices.getUserMedia({video:{width:{exact:CLIENT_VIDEO_RESOLUTION_WIDTH}, height:{exact:CLIENT_VIDEO_RESOLUTION_HEIGHT}, frameRate:{max:CLIENT_VIDEO_FRAMERATE}},
      //navigator.mediaDevices.getUserMedia({video:{width:{exact:640}, height:{exact:360}, frameRate:10},
      //navigator.mediaDevices.getUserMedia({video:{width:{exact:1280}, height:{exact:720}, frameRate:10},
        audio:{channelCount:2, echoCancellation:true, sampleRate:1000, noiseSuppression:true} })
      .then((currentStream) => {

          setStream(currentStream);   
          myVideo.current.srcObject = currentStream;
      }).catch(function(err) {			
        	
			if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {				
				toast.error("Webcam is not found.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//required track is missing 
			} else if (err.name == "NotReadableError" || err.name == "TrackStartError") {				
				toast.error("webcam or mic are already in use.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//webcam or mic are already in use 
			} else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {				 
			} else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {				
				toast.error("permission denied in browser to access webcam or mic.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
			} else if (err.name == "TypeError" || err.name == "TypeError") {
			} else {
				toast.error("Not able to access webcam or mic.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
			}
		});
	  
	  socket.on("me",(id) => {
      setMe(id)
    }); 		  
    
	  socket.on("endCall", () => { 
			  alert("Host ended the call.You can close the window.");
        window.location.href=WEBSITE_URL;
	  });
	  
	  socket.on("callUser", ({ from, name: callerName, signal }) => { setCall({ isReceivingCall: true, from, name: callerName, signal }); });
    socket.on("declineCallRcv", () => { 
      alert("Your call is declined");
      window.location.href=WEBSITE_URL;
    });

  socket.on("msgRcv", ({ name, msg: value, sender }) => {
    setMsgRcv({ value, sender });
    setTimeout(() => { setMsgRcv({});}, 2000);
  });
  
  socket.on("updateUserMedia", ({ type, currentMediaStatus }) => {
    
    if (currentMediaStatus !== null || currentMediaStatus !== []) {
      switch (type) {
        case "video":
          setUserVdoStatus(currentMediaStatus);
          break;
        case "mic":
          setUserMicStatus(currentMediaStatus);
          break;
        default:
          setUserMicStatus(currentMediaStatus[0]);
          setUserVdoStatus(currentMediaStatus[1]);
          break;
      }
    }
  });
  }, []);

  const setClientDetails = (data) => {
    setUserName(data.name);
    setClientName(data.name);
    setName(data.name);
    setclient_referenceno(data.referenceno);
    setclientorgid(data.orgid);
  }

  const callUser = async(id) => {

  	if(stream) {
			
		if(streamflag) return;		
      setstreamflag(true);		  
      const resp = await axios.get(SERVER_URL+'getsocketid',{params:{id:clientorgid}});		
      var socketdt=resp.data;
      if(socketdt.status) {	

        var id=socketdt.hostid;
        var clientsocketid=socket.id;		
        sethostreferenceno(socketdt.hostreferenceno);
        setMe(clientsocketid);	

        setOtherUser(id);	
        const peer = new Peer({ initiator: true, trickle: false, stream});	
            
          peer.on('error', (err) => {				
            alert("Connection failed.Please try to connect again.");
            window.location.reload();
            return false;
          });	

        peer.on("signal", (data) => {
          socket.emit("callUser", {userToCall:id, signalData: data, from: clientsocketid, name,});
        });

        peer.on("stream", (currentStream) => {	
          userVideo.current.srcObject = currentStream;
        });

        socket.on("callAccepted", ({ signal, userName }) => {
          setCallAccepted(true);
          setUserName(userName);
          peer.signal(signal);
          socket.emit("updateMyMedia", { type: "pan", currentMediaStatus: [myMicStatus, myVdoStatus], });    
          var client_obj={};
          client_obj['referenceno']=client_referenceno;
          client_obj['browsername']=browserName.toString();
          socket.emit("updateClientDatatoHost", { to: id, name:clientName,referenceno:client_obj});
        });    
        connectionRef.current = peer;
      }
      else {			
        alert("Host not Available");			
        //window.location.reload();
      }

  	}
  };

  const updateVideo = () => {
    setMyVdoStatus((currentStatus) => {
      socket.emit("updateMyMedia", { type: "video", currentMediaStatus: !currentStatus, });
      stream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const updateMic = () => {
    setMyMicStatus((currentStatus) => {
      socket.emit("updateMyMedia", { type: "mic", currentMediaStatus: !currentStatus, });
      stream.getAudioTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const leaveCall = () => {
    setCallEnded(true);
    connectionRef.current.destroy();
    socket.emit("endCall", { id: otherUser,host_referenceno:hostreferenceno});	
    window.location.href=WEBSITE_URL;
  };

  const leaveCall1 = () => { socket.emit("endCall", { id:otherUser,host_referenceno:hostreferenceno}); };

  const sendMsg = (value) => {
    
    socket.emit("msgUser", { name, to: otherUser, msg: value, sender: name });
    let msg = {};
    msg.msg = value;
    msg.type = "sent";
    msg.timestamp = Date.now();
    msg.sender = name;
    setChat([...chat, msg]);
  };

  useEffect(() => {	  
	if(stream) {


	    	setinitcall(true);
		    callUser('');		
    }		
  },[stream]);
  
  return (
    <ClientVideoContext.Provider
      value={{ call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser, leaveCall, setClientDetails,
        sendMsg, msgRcv, chat, setChat, setMsgRcv, setOtherUser, leaveCall1, userName, myVdoStatus,
        setMyVdoStatus, userVdoStatus, setUserVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic,
        clientName,otherUser,curentcallerType,client_referenceno,initcall,endcallMessage}}
    >
      {children}
    </ClientVideoContext.Provider>
  );
};
export default ClientVideoState;
