import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./HostHeader.css";
import HostVideoContext from "../HostVideoState/HostVideoContext";
import {FullScreen,useFullScreenHandle} from "react-full-screen";
import HostOptions from "../HostOptions/HostOptions";
import HostVideo from "../HostVideo/HostVideo";
import { Container, Row, Col } from 'react-bootstrap';
import { useStopwatch } from 'react-timer-hook';
import LoadingOverlay from 'react-loading-overlay-ts';
const HostHeader = ({hostObj}) => {

  const { callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser,
    leaveCall, answerCall, msgRcv, chat, setChat, userName, myVdoStatus,
    userVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic,hostlogout,loaderoverlaystatus,loaderoverlaytext
  } = useContext(HostVideoContext);

  const handle=useFullScreenHandle();
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const [isOverlayActive,setOverlayActive] = useState(false);

  	useEffect(async() => {
		setOverlayActive(loaderoverlaystatus);
	},[loaderoverlaystatus]);
	
	useEffect(async() => {	  
		if(callAccepted) {
			start();
		}
	  },[callAccepted]);
	
	  
	const logout=()=> {		
		sessionStorage.setItem("hostdata","");
		hostlogout();
	};
	
    return (	
		  <div>
			<LoadingOverlay active={isOverlayActive} spinner text={loaderoverlaytext}>
			  <FullScreen handle={handle}>
				<div className="mainheader">
				  	<Row>
						<Col md="2" className="pd-10 orgname">Video Chat</Col>
					  	<Col md="2" className="pd-10 pdl-10 pdr-10">
							
						</Col>					  
						<Col md="4" className="pd-10">

						</Col>					  	
						<Col md="1" className="pd-10 pdl-0 pdr-0 align-center">		

						</Col>
						<Col md="1" className="pd-10 pdl-0 pdr-0 align-center mgt-10">

						</Col>					  
						<Col md="2" className="pd-10 pdl-0 pdr-0">

						</Col>
					</Row>	
				</div>
			 <HostVideo/>
			 <HostOptions/>
			</FullScreen>
			</LoadingOverlay>			
		</div>
	);
}
export default HostHeader;
