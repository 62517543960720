import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router';
import { Row,Col,Card,Button} from 'react-bootstrap';
import '../../Config.js';
import { matchPath } from 'react-router';
import "./ClientWindow.css";
import "../ClientVideo/ClientVideo.css";
import ClientVideo from "../ClientVideo/ClientVideo";
import ClientVideoState from "../ClientVideoState/ClientVideoState";
import ClientOptions from "../ClientOptions/ClientOptions";
import Footer from "../Footer/Footer";
import 'react-toastify/dist/ReactToastify.css';

function ClientWindow() {

  const { id } = useParams();  
  const [clientId, setclientId] = useState('');  
  const [clientDataObj,setclientDataObj] = useState("");
  useEffect( async() => {

    let pathname=window.location.pathname;
    let path="/client/:id";
    const match = matchPath(pathname, {path})
    let params=match?.params || {};
    var obj={};
    obj['referenceno']="1234567890";
    obj['orgid']=1;
    obj['name']=params.id;
    setclientDataObj(obj);
  },[]);

  return (
    <div>	   
        <ClientVideoState>
		    <div className="App" style={{height:"100%",width:"100%",minHeight:"350px"}}>
			    <ClientVideo clientDataObj={clientDataObj}/>
				<ClientOptions/>
		    </div>
        </ClientVideoState>
		<Footer/>
    </div>
  );
}
export default ClientWindow;

