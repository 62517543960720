import React, { useState,useEffect} from 'react';
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientWindow from "./components/ClientWindow/ClientWindow";
import HostWindow from "./components/HostWindow/HostWindow";

const App = () => {

  return (
    <Router>      
      <Switch>     
        <Route exact path="/" component={HostWindow} />   			
        <Route exact path="/client/:id" render={(props) => (
            <ClientWindow id={props.match.params.id}/>
        )}/>
      </Switch>
    </Router>
  );
};

export default App;
