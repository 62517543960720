import React, { useContext, useEffect, useState, useRef,useLayoutEffect,useCallback} from "react";
import ClientVideoContext from "../ClientVideoState/ClientVideoContext";
import { Row,Col,Card,Button} from 'react-bootstrap';
import "./ClientVideo.css";
import { Input, notification, Avatar } from "antd";
import {UserOutlined, MessageOutlined } from "@ant-design/icons";
import VideoIcon from "../../assests/video.svg";
import VideoOff from "../../assests/video-off.svg";
import { socket } from "../ClientVideoState/ClientVideoState";
import ClientHeader from "../ClientHeader/ClientHeader";
import LoadingOverlay from 'react-loading-overlay-ts';
import Message from "../Message";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Search } = Input;
var key='';
var value='';
const params = {}

window.location.search.substr(1).split('&').forEach(pair => {
  [key,value] = pair.split('=')
  params[key] = value
})

const AADHAR_VALIDATION=global.AADHAR_VALIDATION;
const ClientVideo = ({clientDataObj}) => {
		
	const { callAccepted, myVideo, userVideo, stream, callEnded, setClientDetails, sendMsg: sendMsgFunc, msgRcv, 
		chat, setChat, myVdoStatus, userVdoStatus, clientVideoHeight, updateVideo, myMicStatus, userMicStatus, updateMic, videoabsolutecontainer,initcall,endcallMessage
	} = useContext(ClientVideoContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [client_VideoHeight,setclient_VideoHeight] = useState(clientVideoHeight);
  const [isOverlayActive,setOverlayActive] = useState(false);

  useEffect(() => { 
      setClientDetails(clientDataObj);
   },[]);
  useEffect(() => { setclient_VideoHeight(clientVideoHeight);},[clientVideoHeight]);
  
  const handleButtonClicked = useCallback(() => {
    setOverlayActive(value => !value)
  }, [])
  
  useEffect(() => {   
	 if(callAccepted) {
		handleButtonClicked(); 
	 }
  },[callAccepted]);
  
  useEffect(() => {   
	 if(initcall) {
		handleButtonClicked(); 
	 }
  },[initcall]);
  
  socket.on("msgRcv", ({ name, msg: value, sender }) => {
    let msg = {};
    msg.msg = value;
    msg.type = "rcv";
    msg.sender = sender;
    msg.timestamp = Date.now();
    setChat([...chat, msg]);    
  });

  const dummy = useRef();
  useEffect(() => { if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" }); }, [chat]);
  const showModal = (showVal) => { setIsModalVisible(showVal); };
  useEffect(() => {
    if (msgRcv.value && !isModalVisible) {
      notification.open({
        message: "",
        description: `${msgRcv.sender}: ${msgRcv.value}`,
        icon: <MessageOutlined style={{ color: "#108ee9" }} />,
      });
    }
  }, [msgRcv]);

  return (  
    <div>	
		<LoadingOverlay active={isOverlayActive} spinner text='Connecting...'>		

    	<ClientHeader orgObj={clientDataObj}/>         
			  {stream ? (
				<div className={ videoabsolutecontainer ? 'video-container-absolute' : ''} id="video2" >
					<div className="align-center client-call-window">
					
          {!callEnded  && (
            
				      	<video playsInline muted ref={myVideo}  autoPlay className="video-active"
						      	height={'100%'} style={{ opacity: `${myVdoStatus ? "1" : "0"}` }} />	
                  )}

                  <Avatar
									style={{
										backgroundColor: "#182842",
										position: "relative",
										'margin-top':"10%",
										'line-height':"200px",
										opacity: `${myVdoStatus ? "-1" : "2"}`,
									}}
									size={250}
									icon={<UserOutlined />}
									>
									
									</Avatar>

					</div>
					
          <Col md="12">
									
								<div className="iconsDiv">
								  <div className="icons" onClick={() => {updateMic(); }} tabIndex="0" >
									<i className={`fa fa-microphone${myMicStatus ? "" : "-slash"}`}
									  style={{ transform: "scaleX(-1)" }}
									  aria-label={`${myMicStatus ? "mic on" : "mic off"}`}
									  aria-hidden="true" ></i>
								  </div>

								  <div className="icons" onClick={() => updateVideo()} tabIndex="0">
									{myVdoStatus ? (
									  <img src={VideoIcon} alt="video on icon" />
									) : ( <img src={VideoOff} alt="video on icon" /> )}
								  </div>								  
								</div>									
							  </Col>


			</div>) : ( <div className="bouncing-loader"> <div></div> </div> )}    
			
      {!callEnded  && (
        <div id="video2">
				<video id="host_video" playsInline ref={userVideo} autoPlay className="video-active"
					style={{ opacity: `${userVdoStatus ? "1" : "0"}`, }} /> 
           <Avatar
									style={{
										backgroundColor: "#182842",
										position: "absolute",				
                    'bottom':'5%',
                    'right':'0',			
                    'margin-right':'100px',			
										'line-height':"80px",
										opacity: `${userVdoStatus ? "-2" : "3"}`,
									}}
									size={100}
									icon={<UserOutlined />}
									>
									
									</Avatar>
          </div>
          
      )}

      {endcallMessage &&(
        <Message msg={"Call Ended"} />
      )}
      
		</LoadingOverlay>			
		<ToastContainer />
    </div>
  );
};
export default ClientVideo;
