import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./ClientHeader.css";
import Logo from "../../assests/orient.png";
import {Row, Col } from 'react-bootstrap';
const ClientHeader = ({orgObj}) => {

    return (
      <header className="mainheader">
          <Row>
              <Col md="2" xs="3" className="pdl-10 brandname">Video Chat</Col>
              <Col md="4" xs="8" className="pdl-10 orgname"></Col>
              <Col md="6" xs="0" className="pd-10"></Col>
          </Row>
    </header>);
}
export default ClientHeader;
