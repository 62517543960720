import React, { useState,useEffect} from 'react';
import { useParams } from 'react-router';
import { Row,Col,Card,Button} from 'react-bootstrap';
import '../../Config.js';
import "./HostWindow.css";
import "../HostVideo/HostVideo.css";
import HostHeader from "../HostHeader/HostHeader";
import HostVideoState from "../HostVideoState/HostVideoState";
import Footer from "../Footer/Footer";
const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
function HostWindow() {
  const { id } = useParams();
  const [hostId, sethostId] = useState('');  
  const [hostDataObj,sethostDataObj] = useState("");
   
  return (
    <div>		
		<HostVideoState>
			<div className="App" style={{ height: "auto", width: "auto"}}>
				<HostHeader hostObj={hostDataObj}/>
				<Footer/>
			</div>
		</HostVideoState>
    </div>
  );
}
export default HostWindow;
