import React, { useState, useEffect, useRef } from "react";
import HostVideoContext from "./HostVideoContext";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import $ from "jquery";
import {createWorker} from "tesseract.js";
import * as faceapi from 'face-api.js';
import axios from 'axios';
import '../../Config.js';
import {setMediaBitrate} from '../../mediaBitRate.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SERVER_URL=global.CONFIG_SERVER_URL;
const HOST_VIDEO_RESOLUTION_WIDTH=global.HOST_VIDEO_RESOLUTION_WIDTH;
const HOST_VIDEO_RESOLUTION_HEIGHT=global.HOST_VIDEO_RESOLUTION_HEIGHT;
const HOST_VIDEO_FRAMERATE=global.HOST_VIDEO_FRAMERATE;
const video_bandwidth=global.videoBandwidth;

var callertype='host';
export const socket=io(SERVER_URL, { 
  reconnection: true,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 5000,
  query:{ "type":"host" }
});

const HostVideoState = ({ children}) => {

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [chat, setChat] = useState([]);
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [userName, setUserName] = useState("");
  const [otherUser, setOtherUser] = useState("");
  const [clientName, setClientName] = useState("");
  const [hostreferenceno,sethostreferenceno] = useState("");  
  const [hostorgid, sethostorgid] = useState("");
  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState(true);
  const [msgRcv, setMsgRcv] = useState("");
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  

  useEffect(() => {
	  	
		navigator.mediaDevices
      .getUserMedia({video:{width: {exact:HOST_VIDEO_RESOLUTION_WIDTH},height:{exact:HOST_VIDEO_RESOLUTION_HEIGHT}, frameRate:{max:HOST_VIDEO_FRAMERATE}},
        audio:{channelCount:2, echoCancellation:true, sampleRate:1000, noiseSuppression:true} })
      .then((currentStream) => {
        setStream(currentStream);   
        myVideo.current.srcObject = currentStream;
		  }).catch(function(err) {		

			if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {				
				toast.error("Webcam is not found.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//required track is missing 
			} else if (err.name == "NotReadableError" || err.name == "TrackStartError") {				
				toast.error("webcam or mic are already in use.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//webcam or mic are already in use 
			} else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
				//constraints can not be satisfied by avb. devices 
			} else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {				
				toast.error("permission denied in browser to access webcam or mic.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
				//permission denied in browser 
			} else if (err.name == "TypeError" || err.name == "TypeError") {
				//empty constraints object 
			} else {
				toast.error("Not able to access webcam or mic.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
			}
		});
	
	socket.on("me", (id) => setMe(id)); 
  socket.on("endCall", () => { 
    alert("Client ended the call.");    
    window.location.reload();
  });

	socket.on("callUser", ({ from, name: callerName, signal }) => {setCall({ isReceivingCall: true, from, name: callerName, signal }); });
	
	  socket.on("msgRcv", ({ name, msg: value, sender }) => {
		  setMsgRcv({ value, sender });
		  setTimeout(() => { setMsgRcv({});}, 2000);
	  });
	  
	  socket.on("updateUserMedia", ({ type, currentMediaStatus }) => {
	  	if (currentMediaStatus !== null && currentMediaStatus !== []) {
		    switch (type) {
			    case "video":
			      setUserVdoStatus(currentMediaStatus);
			      break;
			    
          case "mic":
			       setUserMicStatus(currentMediaStatus);
			      break;
			
          default:
			      setUserMicStatus(currentMediaStatus[0]);
			      setUserVdoStatus(currentMediaStatus[1]);
			      break;
		    }
		  }
	  });
    
    setHostData();

  }, []);
  
  const setHostData = () => {

		  let referenceno="9876543210";		
      let orgid='1';	
		  sethostreferenceno(referenceno);	
      sethostorgid(orgid);	
  		let socket_id=socket.id;		
	  	if(socket_id == null) {
		  	socket.on('connect', () => {
			  	sessionStorage.setItem("hostsocketid",socket.id);
				  socket.emit("setreferencenotosocket",{socketid:socket.id,referenceno:referenceno,orgid:orgid}); 			
			  });
		  }
		  else {		
			  sessionStorage.setItem("hostsocketid",socket.id);		
			  socket.emit("setreferencenotosocket",{socketid:socket_id,referenceno:referenceno,orgid:orgid}); 
		  }			
  }
  
  const answerCall = async () => {

    setCallAccepted(true);    
    setOtherUser(call.from);

    //const peer = new Peer({ initiator: true, trickle: false, stream });		
    const peer = new Peer({ initiator: false, trickle: false, stream : stream, 
      sdpTransform: (sdp) => {
        //const sdp2 = setMediaBitrate(setMediaBitrate(sdp, 'video',video_bandwidth), 'audio',audio_bandwidth);
        const sdp2 =  setMediaBitrate(sdp, 'video',video_bandwidth);
        return sdp2;
      } 
    });	

    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from, userName: name, type: "both",
        myMediaStatus: [myMicStatus, myVdoStatus], });
    });
	
	//peer.on('error',err => console.log('error',err));

  peer.on('error', (err) => {	
    console.log(err.code);
		alert("Connection failed.Please try to connect again.");
		window.location.reload();
		return false;
	});	
	
  peer.on("stream", (currentStream) => {
    userVideo.current.srcObject = currentStream;
  });	
  
  peer.signal(call.signal);
  connectionRef.current = peer;		
};

  function wait(delayInMS) {
    return new Promise(resolve => setTimeout(resolve, delayInMS));
  }

  
  const setClientDetails = (data) => {

    setUserName(data.name);
    setClientName(data.name);
    setName(data.name);
  }

  const updateVideo = () => {

    setMyVdoStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "video", currentMediaStatus: !currentStatus,
      });
      stream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const updateMic = () => {
    setMyMicStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "mic", currentMediaStatus: !currentStatus,
      });

     stream.getAudioTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const leaveCall = () => {	  
    setCallEnded(true);
    connectionRef.current.destroy();
    socket.emit("endCall", { id: otherUser,host_referenceno:hostreferenceno,host_socketid:socket.id});	
	  window.location.reload();
  };
  
  const declineCall =() => {	  
    socket.emit("declineCall", { id: otherUser,host_referenceno:hostreferenceno,host_socketid:socket.id});	
	  window.location.reload();
  }
  
  const hostlogout = () => {
    socket.emit("hostlogout",{host_referenceno:hostreferenceno});
  	sessionStorage.setItem("hostsocketid",null);
	  sessionStorage.getItem("hostdata",null);	
    window.location.reload();
  }

  const leaveCall1 = () => { socket.emit("endCall", { id: otherUser,host_referenceno:hostreferenceno }); };
  const sendMsg = (value) => {
    
    socket.emit("msgUser", { name, to: otherUser, msg: value, sender: name });
    let msg = {};
    msg.msg = value;
    msg.type = "sent";
    msg.timestamp = Date.now();
    msg.sender = name;
    setChat([...chat, msg]);
  };

  return (
    <HostVideoContext.Provider
      value={{ call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, leaveCall, setClientDetails,
        answerCall, sendMsg, msgRcv, chat, setChat, setMsgRcv, setOtherUser, leaveCall1, userName, myVdoStatus,
        setMyVdoStatus, userVdoStatus, setUserVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic,declineCall,setHostData,hostlogout,
      }}
    >
      {children}
    </HostVideoContext.Provider>
  );
};
export default HostVideoState;
