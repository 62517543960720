import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./HostVideo.css";
import {Input, notification, Avatar } from "antd";
import VideoIcon from "../../assests/video.svg";
import VideoOff from "../../assests/video-off.svg";
import userImage from "../../user.png";
import {UserOutlined, MessageOutlined } from "@ant-design/icons";
import { socket } from "../HostVideoState/HostVideoState";
import HostVideoContext from "../HostVideoState/HostVideoContext";
import { Row,Col} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Search } = Input;
const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
const HostVideo = ({hostdata}) => {

  const { call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, setClientDetails,
    leaveCall, answerCall, sendMsg: sendMsgFunc, msgRcv, chat, setChat, userName, myVdoStatus,
    userVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic
  } = useContext(HostVideoContext);

  const [sendMsg, setSendMsg] = useState("");
  const [host_flag, setHostflag] = useState(false);
  const [client_flag, setClientflag] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  socket.on("msgRcv", ({ name, msg: value, sender }) => {
    
    let msg = {};
    msg.msg = value;
    msg.type = "rcv";
    msg.sender = sender;
    msg.timestamp = Date.now();
    setChat([...chat, msg]);    
  });

  const dummy = useRef();

  useEffect(() => {
    if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  const showModal = (showVal) => {
    setIsModalVisible(showVal);
  };

  const onSearch = (value) => {
    if (value && value.length) sendMsgFunc(value);
    setSendMsg("");
  };

  useEffect(() => {
    if (msgRcv.value && !isModalVisible) {
      notification.open({
        message: "",
        description: `${msgRcv.sender}: ${msgRcv.value}`,
        icon: <MessageOutlined style={{ color: "#108ee9" }} />,
      });
    }
  }, [msgRcv]);

  return (
    <div>      
		<div>
			<Row>
				<Col md="12">				
						

						{stream ? (		
							<div>
								<div style={{ textAlign: "center" }} id={callAccepted && !callEnded ? "video1" : "video3"}>             
								<div className="client-call-window">   
								  <video playsInline muted ref={myVideo}  autoPlay className="video-active"
									style={{  opacity: `${myVdoStatus ? "1" : "0"}`}}/>		

									<Avatar
									style={{
										backgroundColor: "#182842",
										position: "relative",
										'margin-top':"10%",
										'line-height':"200px",
										opacity: `${myVdoStatus ? "-1" : "2"}`,
									}}
									size={250}
									icon={!name && <UserOutlined />}
									>
									
									</Avatar>
									

								</div>
								</div>
								
								<div className="iconsDiv">
									
								  <div className="icons" onClick={() => {updateMic(); }} tabIndex="0" >
									<i className={`fa fa-microphone${myMicStatus ? "" : "-slash"}`}
									  style={{ transform: "scaleX(-1)" }}
									  aria-label={`${myMicStatus ? "mic on" : "mic off"}`}
									  aria-hidden="true" ></i>
								  </div>

								  <div className="icons" onClick={() => updateVideo()} tabIndex="0">
									{myVdoStatus ? (
									  <img src={VideoIcon} alt="video on icon" />
									) : ( <img src={VideoOff} alt="video on icon" /> )}
								  </div>	
								  							  
								</div>									
							</div>
							) : (<div className="bouncing-loader">
								<div></div>
								<div></div>
								<div></div>
							  </div>
							)}
						
					<div>							
							{callAccepted && !callEnded && userVideo && myVideo && (
								<div id="video2" className="align-center" >								                                                           										
									<video id="localVideo" playsInline ref={userVideo} autoPlay style={{  opacity: `${userVdoStatus ? "1" : "0"}`}}/> 

									<Avatar
									style={{
										backgroundColor: "#182842",
										position: "absolute",				
										'bottom':'5%',
										'right':'0',			
										'margin-right':'100px',			
										'line-height':"80px",
										opacity: `${userVdoStatus ? "-2" : "3"}`,
									}}
									size={100}
									icon={<UserOutlined />}
									>
									
									</Avatar>

								</div>													
							)}
						
					</div>
				</Col>				
			</Row>   
		</div>
	 {/*} )} */}
        <ToastContainer />
  </div>
  );
};

export default HostVideo;
