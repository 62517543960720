import React, { useState, useContext, useEffect, useRef } from "react";
import { Input, Button, Tooltip, Modal, message } from "antd";
import Phone from "../../assests/phone.gif";
import Teams from "../../assests/teams.mp3";
import * as classes from "./Options.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ClientVideoContext from "../ClientVideoState/ClientVideoContext";
import Hang from "../../assests/hang.svg";

import {
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";

import {
  UserOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { socket } from "../ClientVideoState/ClientVideoState";
const ClientOptions = () => {

  const [idToCall, setIdToCall] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const Audio = useRef();

  const {
    call,
    callAccepted,
    myVideo,
    userVideo,
    stream,
    name,
    setName,
    callEnded,
    me,
    callUser,
    leaveCall,
    answerCall,
    otherUser,
    setOtherUser,
    leaveCall1,
    hostcallerType,
    clientcallerType,
    otpflag
  } = useContext(ClientVideoContext);

  useEffect(() => {

    if (isModalVisible) {
      Audio?.current?.play();
    } else Audio?.current?.pause();
  }, [isModalVisible]);

  const showModal = (showVal) => {
    setIsModalVisible(showVal);
  };

  const handleCancel = () => {

    setIsModalVisible(false);
    leaveCall1();
    window.location.reload();
  };

  useEffect(() => {

    if (call.isReceivingCall && !callAccepted) {
      setIsModalVisible(true);
      setOtherUser(call.from);
    } else setIsModalVisible(false);
  }, [call.isReceivingCall]);
  
  return (
    <div className="options_layer">
	
      <div style={{ marginBottom: "0.5rem" }} className={classes.align_center}>
        {callAccepted && !callEnded ? (
              <Button
                  variant="contained"
                  onClick={leaveCall}
                  className="ant-btn Options_hangup_btn__2wRBt"    tabIndex="0">				
              <img src={Hang} alt="hang up" style={{height:"15px"}}/>
              &nbsp; <span className="text_white">Hang up</span>			
            </Button>  
        ) : (
          <div>
            {clientcallerType && (              
                <Button
                  style={{display:'none'}}
                  type="primary"
                  icon={<PhoneOutlined />}
                  onClick={() => {
                    if (name.length) callUser(idToCall);
                    else message.error("Please enter your name to call!");
                  }}
                  className="Options_hang__2DAHh"
                  tabIndex="0"
                >
                  Call
                </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientOptions;
